/**
 *
 * This is the Modules WP page template with the Flexible fields
 *
 */

import React, { useRef, useEffect } from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import * as ACFModules from "../modules/Modules"
import Fallback from "../modules/Fallback"
import ASScroll from "../components/ASScroll/ASScroll"
import ArrowRight from "../images/dark-arrow-right.svg"
import Footer from "../modules/Footer/Footer"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
// import { Context } from '../utils/store';

const ModulePages = ({ data: { wpPage, wpMenu, wp, allWpGfForm } }) => {
  const { template, slug, uri, ancestors, seo } = wpPage
  const {
    pageModules: { modules },
  } = template
  const mainRef = useRef(null)
  const footerRef = useRef(null)

  // const [state, dispatch] = useContext(Context);

  // dispatch({ type: 'scrollType', payload: 'horizontal' });

  const scrollToNext = () => {
    const asscroll = window.scroll
    const scroll = { value: asscroll.currentPos }
    const section = mainRef.current.querySelectorAll("section")[1]

    if (section) {
      // asscroll.disable({ inputOnly: true })

      gsap.to(scroll, {
        value: section.offsetLeft - 66,
        duration: 1,
        ease: "expo.out",
        onUpdate: () => {
          asscroll.scrollTo(scroll.value)
        },
        // onComplete: () => {
        //   asscroll.enable()
        // }
      })
    }
  }

  useEffect(() => {
    let st = false
    const footer = footerRef.current

    if (window.innerWidth >= 1024) {
      st = ScrollTrigger.create({
        trigger: footer,
        start: "left right",
        end: "left left",
        horizontal: true,
        onUpdate: ({ progress }) => {
          if (progress > 0) {
            document
              .querySelector(".start-a-project-btn")
              .classList.add("light")
            document.querySelector(".scroll-btn").classList.add("hide")
          } else {
            document
              .querySelector(".start-a-project-btn")
              .classList.remove("light")
            document.querySelector(".scroll-btn").classList.remove("hide")
          }
        },
      })

      return () => {
        st.kill()
      }
    }
  }, [footerRef])

  return (
    <>
      {slug === "home" ? (
        <h1
          style={{
            position: "absolute",
            textIndent: "-9999px",
            top: 0,
            left: 0,
          }}
        >
          3D Rendering Studio | VR Agency Brisbane, Sydney, Melbourne & Perth
        </h1>
      ) : (
        ""
      )}
      <ASScroll horizontalScroll={true} />
      <Seo seo={seo} />
      <main
        ref={mainRef}
        className={`page page--modules slug-${slug} horizontal-scroll`}
        asscroll-container="true"
      >
        {modules &&
          modules.map((module, id) => {
            if (Object.keys(module).length > 0) {
              const moduleName = module.fieldGroupName.replace(
                "HorizontalTemplate_Pagemodules_Modules_",
                ""
              )
              const Component = ACFModules[moduleName] || Fallback

              if (moduleName === "Form" || moduleName === "SupportBlock") {
                return <Component key={id} {...module} forms={allWpGfForm} />
              } else if (moduleName === "NextPage") {
                return (
                  <Component
                    key={id}
                    ref={footerRef}
                    {...module}
                    currentSlug={slug}
                  />
                )
              }

              return <Component key={id} {...module} />
            }
          })}

        {slug === "home" ? (
          <Footer ref={footerRef} formId={2} isHome={true} />
        ) : (
          ""
        )}
      </main>
      <button className="btn fixed-btn scroll-btn" onClick={scrollToNext}>
        Scroll to explore <ArrowRight />
      </button>
    </>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }
      ancestors {
        nodes {
          ... on WpPage {
            title
            uri
            slug
          }
        }
      }
      template {
        ... on WpHorizontalTemplate {
          pageModules {
            modules {
              ... on WpHorizontalTemplate_Pagemodules_Modules_SingleImage {
                backgroundColour
                fieldGroupName
                moduleId
                imageWidth
                spacing {
                  bottom
                  top
                  bottomMobile
                  topMobile
                }
                horizontalSpacing {
                  right
                  left
                  leftMobile
                  rightMobile
                }
                mp4Video
                image {
                  caption
                  altText
                  width
                  height
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                    }
                  }
                }
                subheading
                title
              }
              ... on WpHorizontalTemplate_Pagemodules_Modules_TextAndList {
                backgroundColour
                fieldGroupName
                list
                moduleId
                spacing {
                  bottom
                  bottomMobile
                  fieldGroupName
                  top
                  topMobile
                }
                horizontalSpacing {
                  right
                  left
                }
                heading
                text
              }
              ... on WpHorizontalTemplate_Pagemodules_Modules_ImageAndText {
                fieldGroupName
                moduleId
                backgroundColour
                imgBackgroundColour
                layout
                spacing {
                  top
                  bottom
                  topMobile
                  bottomMobile
                }
                image {
                  caption
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                  }
                }
                mp4PreviewVideo
                fullVideo
                text
              }
              ... on WpHorizontalTemplate_Pagemodules_Modules_Video {
                backgroundColour
                fieldGroupName
                moduleId
                spacing {
                  bottom
                  top
                  bottomMobile
                  topMobile
                }
                introPixelAnimation
                firstFrameImage {
                  width
                  height
                  sourceUrl
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                    }
                  }
                }
                videoThumbnail
                videoThumbnailMobile
                video
                videoMobile
              }
              ... on WpHorizontalTemplate_Pagemodules_Modules_VerticalSpacer {
                backgroundColour
                fieldGroupName
                spacing {
                  top
                  bottom
                  topMobile
                  bottomMobile
                }
              }
              ... on WpHorizontalTemplate_Pagemodules_Modules_HorizontalSpacer {
                backgroundColour
                fieldGroupName
                horizontalSpacing {
                  left
                  right
                }
              }
              ... on WpHorizontalTemplate_Pagemodules_Modules_Canvas {
                backgroundColour
                fieldGroupName
                moduleId
                image {
                  caption
                  altText
                  width
                  height
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                    }
                  }
                }
                mp4Video
                spacing {
                  bottom
                  top
                  bottomMobile
                  topMobile
                }
              }
              ... on WpHorizontalTemplate_Pagemodules_Modules_RecentProjects {
                backgroundColour
                fieldGroupName
                moduleId
                subheading
                spacing {
                  bottom
                  top
                  bottomMobile
                  topMobile
                }
              }
              ... on WpHorizontalTemplate_Pagemodules_Modules_ImageCollage {
                backgroundColour
                fieldGroupName
                heading
                subheading
                image {
                  caption
                  altText
                  width
                  height
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                    }
                  }
                }
                mp4Video
                moduleId
                link {
                  url
                  title
                  target
                }
                spacing {
                  bottom
                  top
                  bottomMobile
                  topMobile
                }
              }
              ... on WpHorizontalTemplate_Pagemodules_Modules_TwoImageText {
                backgroundColour
                fieldGroupName
                heading
                leftImage {
                  caption
                  altText
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(quality: 100)
                    }
                  }
                }
                rightImage {
                  caption
                  altText
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                  }
                }
                leftVideo
                rightVideo
                moduleId
                subheading
                link {
                  url
                  title
                  target
                }
                spacing {
                  bottom
                  top
                  bottomMobile
                  topMobile
                }
              }
              ... on WpHorizontalTemplate_Pagemodules_Modules_NextPage {
                backgroundColour
                fieldGroupName
                moduleId
                parentPage {
                  ... on WpPage {
                    id
                    uri
                    title
                  }
                }
                nextPage {
                  ... on WpPage {
                    id
                    uri
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ModulePages
